.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms linear 150ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 150ms linear;
}

.btn-rounded{
    width: 250px;
    height: 60px;
    border-radius: 30px;
    font-size:16px;
    font-weight:600;
    color:#000;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.colored{
    background: #FFCA28;
    border: none;
}

.btn-main{
    background-image: none;
    background-color: #0A58CA;
    border-color: #0A58CA;
}